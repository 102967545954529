<template>
  <div>
    <div>
      <b-container fluid>
          <b-row>
              <b-col>
                  <b-card class="my-card">
                    <!-- ====================Navbar start================= -->
                  <div>
                    <b-navbar toggleable="lg" type="dark" class="museum_navbar">
                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                    <b-collapse id="nav-collapse" is-nav>
                      <b-navbar-nav class="ml-auto" style="">
                        <b-nav-item class="museum_btn" href="/virtual-museum/virtual-krishibid">{{$t('pp_museum_info.Feedback_and_review')}}</b-nav-item>
                        <b-nav-item class="museum_btn" href="/virtual-museum/contact-us">{{$t('pp_museum_info.museum_information')}}</b-nav-item>
                      </b-navbar-nav>
                      <b-navbar-nav>
                        <b-nav-item class="museum_btn" :href="virtualAppBaseUrl"  target="_blank" >{{$t('pp_visit_request.virtual_visit')}}</b-nav-item>
<!--                        <b-nav-item class="museum_btn" href="javascript:" v-b-modal.modal-4 @click="resetId">{{$t('pp_visit_request.physical_Visit')}}</b-nav-item>-->
                        <!-- <b-button variant="primary" target="_blank" href="http://service.moa.gov.bd:8095/">{{ $t('pp_visit_request.virtual_visit') }}</b-button> &nbsp; -->
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">{{ $t('pp_visit_request.physical_Visit') }}</b-button> -->
                      </b-navbar-nav>
                    </b-collapse>
                    </b-navbar>
                  </div>
                    <!-- ====================Navbar end=================== -->
                      <!-- <h3 class="text-white t-shadow">{{ $t('externalUserIrrigation.hello')}} <span class="text-info t-shadow" v-if="this.$i18n.locale === 'bn'">{{ authUser.name_bn }}</span> <span class="text-info t-shadow" v-if="this.$i18n.locale === 'en'">{{ authUser.name }}</span>!</h3>
                      <h3 class="text-white t-shadow" v-if="this.$i18n.locale === 'bn'"><span class="text-danger">কৃষি মন্ত্রণালয়</span> এর <span class="text-warning">ইন্টিগ্রেটেড ডিজিটাল পরিষেবা ডেলিভারি প্ল্যাটফর্ম</span> এ আপনাকে স্বাগতম</h3>
                      <h3 class="text-white t-shadow" v-if="this.$i18n.locale === 'en'">Welcome to <span class="text-warning">Integrated Digital Service Delivery Platform Service</span> of <span class="text-danger">Ministry of Agriculture</span></h3>
                      <h4 class="text-white t-shadow">{{ $t('externalUserIrrigation.your_all_service_here') }} <i class="ion-heart text-danger"></i><div data-icon="i" class="icon"></div></h4> -->
                    <img src="../../../../../assets/images/virtual-musium.jpg" alt="">
                  </b-card>
              </b-col>
          </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loadingState: false,
      overlay: true,
      virtualAppBaseUrl: process.env.VUE_APP_VIRTUAL_APP_BASE_URL,
      user: {}
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    authUser () {
       return this.$store.state.Auth.authUser
    }
  },
  mounted () {
    core.index()
  },
  methods: {}
}
</script>

<style scoped>
.min-height {
  height: 20vh;
}
.museum_navbar{
  padding: 0;
  background-color: var(--brand-color);
}
.museum_btn{
  margin-right: 14px;
  border-bottom: 3px solid #4f3886;
  border-radius: 5px;
  background: #5d4793;
  box-shadow: 0px 0px 2px -1px rgb(0 0 0 / 84%);
  transition: .4px all;
}
.museum_btn:hover{
  border-color: #ffffff;
}
.navbar-nav .nav-link {
  color: #ffffff !important;
  padding: 4px 10px!important;
}
.my-card {
  min-height: 20vh;
  /* background-image: url("../../../../../assets/images/login/museum1.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background: none !important;
}
.t-shadow{
  text-shadow: 1px 1px 1px #000;
}
</style>
